/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  sumorphanorphan: 0,
  sumPooRFamily: 0,
}
//
//bondsFlagSlice
export const bondsFlagDiffSlice = createSlice({
  name: 'visibleBondsDiffSliceFlag',
  initialState: { visibleBondsDiffSliceFlag: initialValue },
  reducers: {
    setBondsDiffFlag: (state, action) => {
      state.visibleBondsDiffSliceFlag = action.payload
    },
  },
})

export const { setBondsDiffFlag } = bondsFlagDiffSlice.actions
export default bondsFlagDiffSlice.reducer
