/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  orphanfamilyid: null,
  identifier1phone: null,
  identifier2phone: null,
  recid: null,
  supplierfullname: null,
  supplierfullnameenglish: null,
  suppliernationalnumber: null,
  supplierrelationship: { value: null, label: null },
  suppliersex: { value: null, label: null },
  familyneedmeter: { value: null, label: null },
  sponsorshipstate: { value: 25, label: 'سارية' },
  classification: { value: null, label: null },
  committeename: null,
  committeeid: null,
  committeeareaid: { value: null, label: null },
  fatherfirstname: null,
  fathersecondname: null,
  fatherthirdname: null,
  familyname: null,
  fatherfirstnameenglish: null,
  fathersecondnameenglish: null,
  fatherthirdnameenglish: null,
  familynameenglish: null,
  fathernationalnumber: null,
  fatherdeathdate: null,
  fatherdeathreason: null,
  motherfullname: null,
  mothernationalnumber: null,
  mothermaritalstatus: { value: null, label: null },
  motherjob: null,
  motherincome: null,
  city: { value: null, label: null },
  neighborhood: null,
  street: null,
  nearestphone: null,
  researcherrecomendation: null,
  note: null,
  fatherdeathcertification: null,
  motherdeathcertification: null,
  supplierid: null,
  guardianshippaper: null,
  action: ACTION_ENUM.PREVIEW,
}

export const theMainData = createSlice({
  name: 'visibleMainData',
  initialState: { visibleMainData: initialValue },
  reducers: {
    setMainData: (state, action) => {
      state.visibleMainData = action.payload
    },
    cleanUpMainDataFamilies: (state) => {
      state.visibleMainData = initialValue
    },
  },
})

export const { setMainData, cleanUpMainDataFamilies } = theMainData.actions
export default theMainData.reducer
