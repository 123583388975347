/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  dateCheck3: 25,
}

export const BondsSlice3 = createSlice({
  name: 'visibleBondsSliceData3',
  initialState: { visibleBondsSliceData3: initialValue },
  reducers: {
    setBondsData3: (state, action) => {
      state.visibleBondsSliceData3 = action.payload
    },
  },
})

export const { setBondsData3 } = BondsSlice3.actions
export default BondsSlice3.reducer
