/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  bodyDatabulk:null,

  action: ACTION_ENUM.PREVIEW,
}
export const EnteringChecksSlice = createSlice({
  name: 'visibleEnteringChecksSlice',
  initialState: { visibleEnteringChecksSlice: initialValue },
  reducers: {
    setEnteringChecksData: (state, action) => {
      state.visibleEnteringChecksSlice = action.payload
    },
    cleanEnteringChecksData: (state) => {
      state.visibleEnteringChecksSlice = initialValue
    },
  },
})
export const { setEnteringChecksData, cleanEnteringChecksData } = EnteringChecksSlice.actions
export default EnteringChecksSlice.reducer
