/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  centerid: null,
  centername: null,
  employeeid: {
    value: null,
    label: null,
  },
  serial: null,
  beneficiarytype: {
    value: 1,
    label: 'كفالة الايتام / دورية',
  },
  beneficiaryid: null,
  orphanfullname: null,
  approved: null,
  sponsorshipstate: null,
  donatorname: null,
  donatortel: null,
  donatoraddress: null,
  reservationdate: [new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()].join(
    '-',
  ),
  reservationlongdate: [
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    new Date().getDate(),
  ].join(''),
  inactive: null,
  userid: null,
  rowstamp: null,
  creationuserid: null,
  creationrowstamp: null,
  newflag: null,
  modifiedflag: null,
  deletedflag: null,
  replacementaction: null,
  rowversion: null,
  action: ACTION_ENUM.PREVIEW,
}
export const ReservationSlice = createSlice({
  name: 'visibleReservationData',
  initialState: { visibleReservationData: initialValue },
  reducers: {
    setReservationData: (state, action) => {
      state.visibleReservationData = action.payload
    },
    cleanUpReservationData: (state) => {
      state.visibleReservationData = initialValue
    },
  },
})

export const { setReservationData, cleanUpReservationData } = ReservationSlice.actions
export default ReservationSlice.reducer
