/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recId: null,

  action: ACTION_ENUM.PREVIEW,
}

export const TelephoneBookSlice = createSlice({
  name: 'visibleTelephoneBook',
  initialState: { visibleTelephoneBook: initialValue },
  reducers: {
    setTelephoneBookData: (state, action) => {
      state.visibleTelephoneBook = action.payload
    },
    cleanTelephoneBookData: (state) => {
      state.visibleTelephoneBook = initialValue
    },
  },
})

export const { setTelephoneBookData, cleanTelephoneBookData } = TelephoneBookSlice.actions
export default TelephoneBookSlice.reducer
