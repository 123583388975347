/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  cancelFlag: false,
  hometype: { value: null, label: null },
  buildingstate: { value: null, label: null },
  action: ACTION_ENUM.PREVIEW,
}
export const needyFamiliesSlice = createSlice({
  name: 'visibleNeedyFamiliesSliceData',
  initialState: { visibleNeedyFamiliesSliceData: initialValue },
  reducers: {
    setNeedyFamiliesSliceData: (state, action) => {
      state.visibleNeedyFamiliesSliceData = action.payload
    },
    cleanUpNeedyFamiliesSliceData: (state) => {
      state.visibleNeedyFamiliesSliceData = initialValue
    },
  },
})

export const { setNeedyFamiliesSliceData, cleanUpNeedyFamiliesSliceData } =
  needyFamiliesSlice.actions
export default needyFamiliesSlice.reducer
