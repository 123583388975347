/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  saved: false,
  route: null,
  pressed: null,
  pressSave: false
}

export const UnsavedChangesSlice = createSlice({
  name: 'visibleUnsavedChanges',
  initialState: { visibleUnsavedChanges: initialValue },
  reducers: {
    setUnsavedChangesData: (state, action) => {
      state.visibleUnsavedChanges = action.payload
    },
    cleanUnsavedChangesData: (state) => {
      state.visibleUnsavedChanges = initialValue
    },
  },
})

export const { setUnsavedChangesData, cleanUnsavedChangesData } = UnsavedChangesSlice.actions
export default UnsavedChangesSlice.reducer