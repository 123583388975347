/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recId: null,
  nameprefixtext: { value: null, label: null },
  donatorid: null,
  fullname: null,
  namesuffixtext: { value: null, label: null },
  contactprefixtext: { value: null, label: null },
  contactfullname: null,
  contactsuffixtext: { value: null, label: null },
  invitationnameprefixtext: { value: null, label: null },
  invitationname: null,
  invitationnamesuffixtext: { value: null, label: null },
  mediatorname: null,
  mediatorid: null,
  donatortypetext: { value: null, label: null },
  donationsource: { value: 126, label: 'داخلية' },
  name: { value: null, label: null },
  donationarea: { value: null, label: null },
  address: null,
  addresswork: null,
  telhome: null,
  telwork1: null,
  telwork2: null,
  mobile: null,
  mobilecode: { value: null, label: null },
  mobileowner: null,
  mobile2: null,
  mobilecode2: { value: null, label: null },
  mobileowner2: null,
  fax: null,
  email: null,
  knowingcommittee: { value: 11138, label: "غير محدد" },
  citytext: { value: null, label: null },
  countrytext: { value: null, label: null },
  sendmailway: { value: null, label: null },
  openbalance: null,
  newflag: null,
  note: null,
  zipcode: null,
  pobox: null,
  followedby: { value: null, label: null },
  sendsms: null,

  medmobile: null,
  medmobilecode: { value: null, label: null },
  medmobileowner: null,

  medmobile2: null,
  medmobilecode2: { value: null, label: null },
  medmobileowner2: null,

  medmobile: null,
  medmobilecode: { value: null, label: null },
  medmobileowner: null,
  medmobile2: null,
  medmobilecode2: { value: null, label: null },
  medmobileowner2: null,
  mediatoremail: null,

  code: { value: null, label: null },
  code2: { value: null, label: null },

  action: ACTION_ENUM.PREVIEW,
}
export const theMainData = createSlice({
  name: 'visibleMainData',
  initialState: { visibleMainData: initialValue },
  reducers: {
    setMainData: (state, action) => {
      state.visibleMainData = action.payload
    },
    cleanUpMainData: (state) => {
      state.visibleMainData = initialValue
    },
  },
})

export const { setMainData, cleanUpMainData } = theMainData.actions
export default theMainData.reducer
