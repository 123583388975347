/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recId: null,
  centerid: null,
  centername: ' ',
  orphanfamilyid: null,
  supplierfullname: ' ',
  supplierrelationship: null,
  supplierrelationshiptext: ' ',
  suppliernationalnumber: ' ',
  orphanid: null,
  sponsoredorphanid: null,
  firstname: ' ',
  secondnameAr: '',
  thirdnameAr: '',
  lastnameAr: '',
  secondnameEn: '',
  thirdnameEn: '',
  lastnameEn: '',
  nationalnumber: null,
  sex: null,
  sextext: ' ',
  birthdate: ' ',
  birthlongdate: null,
  birthplace: null,
  relatedtocenter: null,
  inactive: null,
  orphanfullname: ' ',
  approved: true,
  userid: null,
  creationuserid: null,
  extraorphanid: ' ',
  orphansponsorshipstate: null,
  orphansponsorshipstatetext: ' ',
  committeeid: null,
  committeename: '  ',
  committeeareaid: null,
  committeeareaidtext: ' ',
  quranmemorize: null,
  quranmemorizetext: '  ',
  orphanhealth: null,
  orphanhealthtext: ' ',
  orphanhealthnote: null,
  note: null,
  brothernum: null,
  supportednum: null,
  schoolstage: null,
  schoolstagetext: ' ',
  schoolname: null,
  hobby: null,
  reservation: null,
  reservationtext: null,
  reservationdate: null,
  reservationlongdate: null,
  orphansponsorshipstopdate: ' ',
  sponsorshipdefaultamount: ' ',
  managerialdefaultdeduction: ' ',
  researcheropinion: null,
  pic: null,
  birthcertification: null,
  printed: null,
  orphansponsorshipstartstopdate: ' ',
  newreportdemandsent: null,
  orphansponsorshipstopreason: null,
  firstnameenglish: ' ',
  orphanfullnameenglish: ' ',
  action: ACTION_ENUM.PREVIEW,
}
export const OrphansMainDataSlice = createSlice({
  name: 'visibleOrphansData',
  initialState: { visibleOrphansData: initialValue },
  reducers: {
    setOrphanData: (state, action) => {
      state.visibleOrphansData = action.payload
    },
    cleanUpOrphanData: (state) => {
      state.visibleOrphansData = initialValue
    },
  },
})

export const { setOrphanData, cleanUpOrphanData } = OrphansMainDataSlice.actions
export default OrphansMainDataSlice.reducer
