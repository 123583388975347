/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  dateCheck: 1,
}

export const correspondenceSlice = createSlice({
  name: 'visibleCorrespondenceData',
  initialState: { visibleCorrespondenceData: initialValue },
  reducers: {
    setCorrespondenceData: (state, action) => {
      state.visibleCorrespondenceData = action.payload
    },
    cleanCorrespondenceData: (state) => {
      state.visibleCorrespondenceData = initialValue
    },
  },
})

export const { setCorrespondenceData, cleanCorrespondenceData } = correspondenceSlice.actions
export default correspondenceSlice.reducer
