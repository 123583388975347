/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  bookid: null,
  fromvoucher: null,
  tovoucher: null,
  receiptname: null,
  voucherreceiptdate: null,
  voucherreceiptlongdate: null,
  voucherdeliverydate: null,
  voucherdeliverylongdate: null,
  note: null,
  bookvoucheryear: null,
  action: ACTION_ENUM.PREVIEW,
  deliveryvoucherid: null,
  bookdeliveredtozaka: 0,
}
export const bookVoucher = createSlice({
  name: 'visibleBookVoucher',
  initialState: { visibleBookVoucher: initialValue },
  reducers: {
    setBookVoucher: (state, action) => {
      state.visibleBookVoucher = action.payload
    },
    cleanUpBookVoucher: (state) => {
      state.visibleBookVoucher = initialValue
    },
  },
})

export const { setBookVoucher, cleanUpBookVoucher } = bookVoucher.actions
export default bookVoucher.reducer
