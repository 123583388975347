/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  erorrDataamount: false,
}
//bondsFlagSlice
export const bondsFlagAmountSlice = createSlice({
  name: 'visibleBondsSliceAmount',
  initialState: { visibleBondsSliceAmount: initialValue },
  reducers: {
    setBondsFlagAmount: (state, action) => {
      state.visibleBondsSliceAmount = action.payload
    },
  },
})

export const { setBondsFlagAmount } = bondsFlagAmountSlice.actions
export default bondsFlagAmountSlice.reducer
