/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
}

export const ProjectDisbursementsSlice = createSlice({
  name: 'visibleProjectDisbursementsData',
  initialState: { visibleProjectDisbursementsData: initialValue },
  reducers: {
    setProjectDisbursementsData: (state, action) => {
      state.visibleProjectDisbursementsData = action.payload
    },
    cleanProjectDisbursementsData: (state) => {
      state.visibleProjectDisbursementsData = initialValue
    },
  },
})

export const { setProjectDisbursementsData, cleanProjectDisbursementsData } =
  ProjectDisbursementsSlice.actions
export default ProjectDisbursementsSlice.reducer
