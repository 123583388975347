/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  centerid: null,
  centername: null,
  projectid: null,
  projecttype:  { value: null, label: null },
  projecttypetext: null,
  name: null,
  englishname: null,
  recieptaccount: null,
  paymentaccount: null,
  bankid: null,
  zakafree: null,
  zakapercent: null,
  inactive: null,
  userid: null,
  rowstamp: null,
  creationuserid: null,
  creationrowstamp: null,
  newflag: null,
  modifiedflag: null,
  deletedflag: null,
  replacementaction: null,
  rowversion: null,
  action: ACTION_ENUM.PREVIEW,
}
export const CharityProjects = createSlice({
  name: 'visibleCharityProjects',
  initialState: { visibleCharityProjects: initialValue },
  reducers: {
    setCharityProjects: (state, action) => {
      state.visibleCharityProjects = action.payload
    },
    cleanUpCharityProjects: (state) => {
      state.visibleCharityProjects = initialValue
    },
  },
})

export const { setCharityProjects, cleanUpCharityProjects } = CharityProjects.actions
export default CharityProjects.reducer
//CharityProjects
