/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'
const initialState = { showSendEmail: false }
const SendemailModalSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialState },
  reducers: {
    showSendEmailModal: (state, action) => {
      state.visible = action.payload
    },
  },
})
export const { showSendEmailModal } = SendemailModalSlice.actions
export default SendemailModalSlice.reducer
