/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  committeeid: null,
  recId: null,
  establishdate: null,
  establishlongdate: null,
  committeename: '',
  committeeenglishname: '',
  region: { value: '', label: '' },
  committeetype: { value: '', label: '' },
  address: '',
  addressenglish: '',
  committeeworkingarea: '',
  city: { value: '', label: '' },
  country: { value: '', label: '' },
  telwork1: null,
  telwork2: null,
  mobile1: null,
  mobile2: null,
  mobile3: null,
  mobile1holder: '',
  mobile2holder: '',
  mobile3holder: '',
  fax: null,
  email: '',
  webpage: '',
  inactive: false,
  action: ACTION_ENUM.PREVIEW,
}
export const theMainData = createSlice({
  name: 'visibleMainData',
  initialState: { visibleMainData: initialValue },
  reducers: {
    setMainData: (state, action) => {
      state.visibleMainData = action.payload
    },
    cleanUpMainData: (state) => {
      state.visibleMainData = initialValue
    },
  },
})

export const { setMainData, cleanUpMainData } = theMainData.actions
export default theMainData.reducer
