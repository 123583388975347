/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'
const initialValue = {
  recId: null,
  action: ACTION_ENUM.PREVIEW,
}
export const OrphansExpensesSlice = createSlice({
  name: 'visibleOrphansExpenses',
  initialState: { visibleOrphansExpenses: initialValue },
  reducers: {
    setOrphansExpensesData: (state, action) => {
      state.visibleOrphansExpenses = action.payload
    },
    cleanOrphansExpensesData: (state) => {
      state.visibleOrphansExpenses = initialValue
    },
  },
})

export const { setOrphansExpensesData, cleanOrphansExpensesData } = OrphansExpensesSlice.actions
export default OrphansExpensesSlice.reducer
