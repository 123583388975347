/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  donatorid: null,

  paymentmethod: { value: '', label: '' },
  paymenttype: { value: '', label: '' },
  reportingperiod: 12,
  note: null,
  action: ACTION_ENUM.PREVIEW,
}
export const NominationOfFamilies = createSlice({
  name: 'visibleNominationOfFamilies',
  initialState: { visibleNominationOfFamilies: initialValue },
  reducers: {
    setNominationOfFamilies: (state, action) => {
      state.visibleNominationOfFamilies = action.payload
    },
    cleanNominationOfFamilies: (state) => {
      state.visibleNominationOfFamilies = initialValue
    },
  },
})
//NominationOfFamilies
export const { setNominationOfFamilies, cleanNominationOfFamilies } = NominationOfFamilies.actions
export default NominationOfFamilies.reducer
