/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    donatorid: null,
    mediatorid: null,
}
export const stopOrphanSponseship = createSlice({
  name: 'stopOrphanSponseshipData',
  initialState: { stopOrphanSponseshipData: initialValue },
  reducers: {
    setStopOrphanSponsership: (state, action) => {
      state.stopOrphanSponseshipData = action.payload
    },
    cleanUpStopShopnership: (state) => {
      state.stopOrphanSponseshipData = initialValue
    },
  },
})

export const { setStopOrphanSponsership, cleanUpStopShopnership } = stopOrphanSponseship.actions
export default stopOrphanSponseship.reducer
