/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recId: null,

  action: ACTION_ENUM.PREVIEW,
}
export const ReplacedOrphanSponsorshipsSlice = createSlice({
  name: 'visibleReplacedOrphanSponsorshipsData',
  initialState: { visibleReplacedOrphanSponsorshipsData: initialValue },
  reducers: {
    setvisibleReplacedOrphanSponsorshipsData: (state, action) => {
      state.visibleReplacedOrphanSponsorshipsData = action.payload
    },
    cleanUpvisibleReplacedOrphanSponsorshipsData: (state) => {
      state.visibleReplacedOrphanSponsorshipsData = initialValue
    },
  },
})

export const {
  setvisibleReplacedOrphanSponsorshipsData,
  cleanUpvisibleReplacedOrphanSponsorshipsData,
} = ReplacedOrphanSponsorshipsSlice.actions
export default ReplacedOrphanSponsorshipsSlice.reducer
