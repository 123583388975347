/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  erorrOtherProjectsFamily: true,
}
//bondsFlagOtherProjectsFamilySlice
export const bondsFlagOtherProjectsFamilySlice = createSlice({
  name: 'visibleBondsSliceOtherProjectsFamily',
  initialState: { visibleBondsSliceOtherProjectsFamily: initialValue },
  reducers: {
    setBondsFlagOtherProjectsFamily: (state, action) => {
      state.visibleBondsSliceOtherProjectsFamily = action.payload
    },
  },
})
export const { setBondsFlagOtherProjectsFamily } = bondsFlagOtherProjectsFamilySlice.actions
export default bondsFlagOtherProjectsFamilySlice.reducer
