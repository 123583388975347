/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  erorrDatafamilyPeriodic: true,
}
//bondsFlagfamilySlice
export const bondsFlagfamilyPeriodicSlice = createSlice({
  name: 'visibleBondsSlicefamilyPeriodicFlag',
  initialState: { visibleBondsSlicefamilyPeriodicFlag: initialValue },
  reducers: {
    setBondsfamilyPeriodicFlag: (state, action) => {
      state.visibleBondsSlicefamilyPeriodicFlag = action.payload
    },
  },
})

export const { setBondsfamilyPeriodicFlag } = bondsFlagfamilyPeriodicSlice.actions
export default bondsFlagfamilyPeriodicSlice.reducer
