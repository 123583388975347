/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  needyfamilyid: null,
  recid: null,
  needyfamilybankid: { value: '', label: '' },
  needyfamilybankbranchid: { value: '', label: '' },
  acccountstatus: { value: '', label: '' },
  accountnumber: null,
  note: null,
  needyfamilyaccountid: null,
  action: ACTION_ENUM.PREVIEW,
}
export const theMainData = createSlice({
  name: 'visibleMainData',
  initialState: { visibleMainData: initialValue },
  reducers: {
    setMainDataPoorFamiliesAccounts: (state, action) => {
      state.visibleMainData = action.payload
    },
    cleanUpMainDataAccounts: (state) => {
      state.visibleMainData = initialValue
    },
  },
})

export const { setMainDataPoorFamiliesAccounts, cleanUpMainDataAccounts } = theMainData.actions
export default theMainData.reducer
