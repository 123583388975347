/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  action: ACTION_ENUM.PREVIEW,
}
//FollowUpDocuments
export const FollowUpDocumentsSlice = createSlice({
  name: 'visibleFollowUpDocumentsData',
  initialState: { visibleFollowUpDocumentsData: initialValue },
  reducers: {
    setFollowUpDocumentsData: (state, action) => {
      state.visibleFollowUpDocumentsData = action.payload
    },
    cleanFollowUpDocumentsData: (state) => {
      state.visibleFollowUpDocumentsData = initialValue
    },
  },
})

export const { setFollowUpDocumentsData, cleanFollowUpDocumentsData } = FollowUpDocumentsSlice.actions
export default FollowUpDocumentsSlice.reducer
