/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  birthdate: null,
  birthlongdate: null,
}
export const EmployeesSlice2 = createSlice({
  name: 'visibleEmployeeData2',
  initialState: { visibleEmployeeData2: initialValue },
  reducers: {
    setEmployeeData2: (state, action) => {
      state.visibleEmployeeData2 = action.payload
    },
    cleanUpEmployeeData2: (state) => {
      state.visibleEmployeeData2 = initialValue
    },
  },
})

export const { setEmployeeData2, cleanUpEmployeeData2 } = EmployeesSlice2.actions
export default EmployeesSlice2.reducer
