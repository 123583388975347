/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  erorrOtherProjects: true,
}
export const bondsFlagOtherProjectsSlice = createSlice({
  name: 'visibleBondsSliceOtherProjects',
  initialState: { visibleBondsSliceOtherProjects: initialValue },
  reducers: {
    setBondsFlagOtherProjects: (state, action) => {
      state.visibleBondsSliceOtherProjects = action.payload
    },
  },
})
export const { setBondsFlagOtherProjects } = bondsFlagOtherProjectsSlice.actions
export default bondsFlagOtherProjectsSlice.reducer
