/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  flagClosePage:false,
  distribution2: false,

  dateCheck2: 25,
}

export const BondsSlice2 = createSlice({
  name: 'visibleBondsSliceData2',
  initialState: { visibleBondsSliceData2: initialValue },
  reducers: {
    setBondsData2: (state, action) => {
      state.visibleBondsSliceData2 = action.payload
    },
    cleanBondsData: (state) => {
      state.visibleBondsSliceData2 = initialValue
    },
  },
})

export const { setBondsData2, cleanBondsData2 } = BondsSlice2.actions
export default BondsSlice2.reducer
