/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  indexes: null,
}

export const SelectedRowIndexes = createSlice({
  name: 'indexes',
  initialState: { indexes: initialState },
  reducers: {
    setRowIndexes: (state, action) => {
      state.indexes = action.payload
    },
    cleanUpRowIndexes: (state) => {
      state.indexes = initialState
    },
  },
})

export const { setRowIndexes, cleanUpRowIndexes } = SelectedRowIndexes.actions

export default SelectedRowIndexes.reducer
