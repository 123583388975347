/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  //family
  orphanfamilyid: null,
  committeeid: null,
  committeeareaid: null,
  supplierfullname: null,
  fatherfirstname: null,
  fathersecondname: null,
  familyname: null,
  //orphan
  orphanfamilymemberid: null,
  orphanid: null,
  firstname: null,
  orphanfullname: null,
  sex: null,
  birthdate: null,
  birthlongdate: null,
  birthplace: null,
  nationalnumber: null,
  schoolstage: null,
  orphanhealthnote: null,
  note: null,
  familydependent: false,
  maritalstatus: null,
  monthlyincome: null,
  job: null,
  scientificqualification: null,
  monthlymidicationcost: null,
  relationtodeadfather: null,
  action: ACTION_ENUM.PREVIEW,
}
export const orphanFamilyMember = createSlice({
  name: 'visibleOrphanfamilyMember',
  initialState: { visibleOrphanfamilyMember: initialValue },
  reducers: {
    setOrphanfamilyMember: (state, action) => {
      state.visibleOrphanfamilyMember = action.payload
    },
    cleanUpOrphanfamilyMember: (state) => {
      state.visibleOrphanfamilyMember = initialValue
    },
  },
})

export const { setOrphanfamilyMember, cleanUpOrphanfamilyMember } = orphanFamilyMember.actions
export default orphanFamilyMember.reducer
