/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  centerid: null,
  centername: null,
  orphanfamilyid: null,
  supplierfullname: null,
  committeeid: null,
  committeename: null,
  orphanfamilyresearchid: null,
  orphanresearchid: null,
  orphanid: null,
  sex: null,
  sextext: null,
  birthdate: null,
  orphanfullname: null,
  orphanresearchdate: null,
  orphanresearchlongdate: null,
  schoolstate: null,
  schoolstatetext: null,
  schooltype: null,
  schooltypetext: null,
  schoolname: null,
  schoolclass: null,
  schoolclasstext: null,
  schoolfailurereason: null,
  schoolfailurereasontext: null,
  orphanpraystate: null,
  orphanpraystatetext: null,
  orphanpraycommitment: null,
  quranmemorize: null,
  quranmemorizetext: null,
  centeractivitiesparticipatestate: null,
  centeractivitiesparticipatestatetext: null,
  centeractivitiesparticipationlfailurereason: null,
  centeractivitiesparticipationlfailurereasontext: null,
  moralcommitment: null,
  moralcommitmenttext: null,
  orphanhealth: null,
  orphanhealthtext: null,
  orphanhobbies: null,
  orphanneeds: null,
  orphanneedstext: null,
  researcheropinion: null,
  inactive: null,
  approved: null,
  userid: null,
  rowstamp: null,
  creationuserid: null,
  creationrowstamp: null,
  newflag: null,
  modifiedflag: null,
  deletedflag: null,
  replacementaction: null,
  rowversion: null,
  orphanfamilymemberid: null,
  orphanotherneeds: null,
  orphanextrahealthnote: null,
  reportpic: false,
  message: false,
  studycertification: false,
  otherattachments: false,
  sent: null,
  note: null,
  locked: null,
  action: ACTION_ENUM.PREVIEW,
}
export const PeriodicOrphanResearch = createSlice({
  name: 'visibleOrphanResearch',
  initialState: { visibleOrphanResearch: initialValue },
  reducers: {
    setOrphanResearchData: (state, action) => {
      state.visibleOrphanResearch = action.payload
    },
    cleanUpOrphanResearch: (state) => {
      state.visibleOrphanResearch = initialValue
    },
  },
})

export const { setOrphanResearchData, cleanUpOrphanResearch } = PeriodicOrphanResearch.actions
export default PeriodicOrphanResearch.reducer
