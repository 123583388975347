/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
    mediatorid: null,
    nameprefix: { value: null, label: null },
    fullname: null,
    namesuffix: { value: null, label: null },
    donatortype: { value: null, label: null },
    followedby:{ value: null, label: null },
    donationarea:{ value: null, label: null },
    address: null,
    addresswork: null,
    pobox: null,
    zipcode: null,
    city: { value: null, label: null },
    country: { value: null, label: null },
    telhome: null,
    telwork1: null,
    telwork2: null,
    fax: null,
    email: null,
    note: null,
    mobile: null,
    mobilecode: { value: null, label: null },
    mobileowner: null,
    mobile2: null,
    mobilecode2:{ value: null, label: null },
    mobileowner2: null,
    knowingcommittee: { value: null, label: null },
  action: ACTION_ENUM.PREVIEW,
}
export const brokerCard = createSlice({
  name: 'visibleBrokerCard',
  initialState: { visibleBrokerCard: initialValue },
  reducers: {
    setBrokerCard: (state, action) => {
      state.visibleBrokerCard = action.payload
    },
    cleanUpBrokerCard: (state) => {
      state.visibleBrokerCard = initialValue
    },
  },
})

export const { setBrokerCard, cleanUpBrokerCard } = brokerCard.actions
export default brokerCard.reducer