/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  centerid: null,
  centername: null,
  orphanfamilyid: null,
  orphanfamilyresearchid: null,
  familyresearchdate: null,
  hometype: null,
  hometypetext: null,
  buildingstate: null,
  buildingstatetext: null,
  buildingtype: null,
  buildingtypetext: null,
  buildingarea: null,
  roomcount: null,
  buildingnote: null,
  kitchenexist: null,
  kitchenstate: null,
  centralheatingexist: null,
  centralheatingstate: null,
  waterexist: null,
  electricityexist: null,
  sewageexist: null,
  telephoneexist: null,
  otherservicenote: null,
  furniturestate: null,
  famillylandproperty: null,
  famillyhouseproperty: null,
  famillyshareproperty: null,
  famillyrealestateproperty: null,
  famillycarsproperty: null,
  famillyotherproperty: null,
  retirementincome: null,
  insuranceincome: null,
  wageincome: null,
  salariessum: null,
  nationalaid: null,
  commiteeaid: null,
  zakatcommiteeaid: null,
  aidsum: null,
  donator: null,
  relativedonator: null,
  socialaffairs: null,
  childrensupport: null,
  relieffund: null,
  donationsum: null,
  compensation: null,
  propertywageincome: null,
  shareincome: null,
  miscellanysum: null,
  otherincome: null,
  totalincome: null,
  homemonthlycost: null,
  curemonthlycost: null,
  studymonthlycost: null,
  livingmonthlycost: null,
  loanmonthlypayment: null,
  watermonthlycost: null,
  electricitymonthlycost: null,
  telephonemonthlycost: null,
  monthlypaymentssum: null,
  mothermaritalstatus: null,
  familyislamiclevel: null,
  familybreakupexist: null,
  childrenmisbehaveexist: null,
  supplierfullname: null,
  supplierrelationshiptodeadfather: null,
  generalnote: null,
  motherjob: null,
  city: null,
  street: null,
  hometelephone: null,
  neighborhood: null,
  sponsorshipstate: null,
  familyneedmeter: null,
  researcheropinion: null,
  researcher: null,
  suppliersex: null,
  inactive: null,
  approved: null,
  userid: null,
  othercost: null,
  locked: null,
  action: ACTION_ENUM.PREVIEW,
}
export const PeriodicOrphanFamilyResearch = createSlice({
  name: 'visibleOrphansFamilyResearch',
  initialState: { visibleOrphansFamilyResearch: initialValue },
  reducers: {
    setOrphanFamilyResearchData: (state, action) => {
      state.visibleOrphansFamilyResearch = action.payload
    },
    cleanUpOrphanFamilyResearch: (state) => {
      state.visibleOrphansFamilyResearch = initialValue
    },
  },
})

export const { setOrphanFamilyResearchData, cleanUpOrphanFamilyResearch } =
  PeriodicOrphanFamilyResearch.actions
export default PeriodicOrphanFamilyResearch.reducer
