/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  incomevoucherotherprojects: [],
  OrphanDataForEditMonth: [],
  FamilyDataForEditMonth: [],
  dataFamilyOtherProject: [],
  flagReRnder2: 0,
  recId: null,
  flagNullData: false,
  dateCheck: 25,
  incomevoucheryear: parseInt(
    (new Date().getMonth() + 1 < 10
      ? [new Date().getFullYear(), `0${new Date().getMonth() + 1}`, new Date().getDate()].join('')
      : [
          new Date(new Date()).getFullYear(),
          new Date(new Date()).getMonth() + 1,
          new Date(new Date()).getDate(),
        ].join('')
    ).substring(0, 4),
  ),
  incomevouchertype: 129,
  incomevouchertypetext: 'سند قبض',
  distribution: false,
  flagDistrubtion: false,
  flagNullData: false,
  OrphanArrForUpdateAmount: [],
  flagneedyfamilyproject: false,
  flagorphanproject: false,
  flagorphan: false,
  flagneedyfamily: false,
  flagDistribution: false,
  flagDoneNeedyFamilyProjectid2: false,
  flagDoneNeedyFamilyProjectidNot2: false,
  flagDoneOrphanSponsorshipsNot1: false,
  flagTransData: false,
  dataTableProjectForFilter: [],
  dataTableDonators: [],
  MonthCancellation: [],
  ArrForUpdateAmountForFamily2: [],
  ArrForUpdateAmountForFamily: [],
  OrphanArrForUpdateAmount: [],
  OrphanArrForUpdateAmount2: [],
  incomevoucherdonatorsponsorships: [],
  OrphanDataForEditMonth: [],
  FamilyDataForEditMonth: [],

  incomevoucherotherprojects: [],
  dataFamilyOtherProject: [],
  dataForNewStateForFamily: [],
  dataTableProjectForFilter: [],

  flagNewAndUpdateButton: false,
  action: ACTION_ENUM.PREVIEW,
}

export const BondsSlice = createSlice({
  name: 'visibleBondsSliceData',
  initialState: { visibleBondsSliceData: initialValue },
  reducers: {
    setBondsData: (state, action) => {
      state.visibleBondsSliceData = action.payload
    },
    cleanBondsData: (state) => {
      state.visibleBondsSliceData = initialValue
    },
  },
})

export const { setBondsData, cleanBondsData } = BondsSlice.actions
export default BondsSlice.reducer
