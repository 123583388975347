/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  centerid: null,
  centername: '',
  callid: null,
  charityprojectid: null,
  beneficiaryid: null,
  donatorid: null,
  fullname: '',
  serial: null,
  employeeid: null,
  employeename: '',
  contactway: null,
  contactwaytext: '',
  calldate: '',
  calldatelongdate: null,
  subject: null,
  subjecttext: '',
  note: '',
  inactive: null,
  action: ACTION_ENUM.PREVIEW,
}
export const SponsorCommunication = createSlice({
  name: 'visibleCallData',
  initialState: { visibleCallData: initialValue },
  reducers: {
    setCallData: (state, action) => {
      state.visibleCallData = action.payload
    },
    cleanUpCallData: (state) => {
      state.visibleCallData = initialValue
    },
  },
})

export const { setCallData, cleanUpCallData } = SponsorCommunication.actions
export default SponsorCommunication.reducer
