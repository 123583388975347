/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  donatorid: null,
  orphanfamilyid: null,
  orphanid: null,
  branch: { value: 11143, label: 'المكتب الرئيسي/ عمان' },
  sponsorshipamount: 0,
  managerialdeduction: 1,
  otherdeduction: 0,
  sponsorshipnetamount: 0,
  sponsorshipstartdate: [new Date().getFullYear(), new Date().getMonth() + 1, '1'].join('/'),
  sponsorshipdate: [new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()].join(
    '/',
  ),
  paymentmethod: { value: '', label: '' },
  paymenttype: { value: '', label: '' },
  reportingperiod: 12,
  note: null,
  action: ACTION_ENUM.PREVIEW,
}
export const OrphanSponsorShips = createSlice({
  name: 'visibleOrphanSponsorShips',
  initialState: { visibleOrphanSponsorShips: initialValue },
  reducers: {
    setOrphanSponsorShips: (state, action) => {
      state.visibleOrphanSponsorShips = action.payload
    },
    cleanUpOrphanSponsorShips: (state) => {
      state.visibleOrphanSponsorShips = initialValue
    },
  },
})

export const { setOrphanSponsorShips, cleanUpOrphanSponsorShips } = OrphanSponsorShips.actions
export default OrphanSponsorShips.reducer
