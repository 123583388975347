/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = { showErrorSave: false }

const errorSaveSlice = createSlice({
  name: 'visible',
  initialState: initialState,

  reducers: {
    showErrorSaveModal: (state) => {
      //show
      state.showErrorSave = !state.showErrorSave
    },
  },
})

export const { showErrorSaveModal } = errorSaveSlice.actions

export default errorSaveSlice.reducer
//store r
