/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  rows: null,
}

export const MultiSelectSlice = createSlice({
  name: 'rows',
  initialState: { rows: initialState },
  reducers: {
    setRows: (state, action) => {
      state.rows = action.payload
    },
    cleanUpRows: (state) => {
      state.rows = initialState
    },
  },
})

export const { setRows, cleanUpRows } = MultiSelectSlice.actions

export default MultiSelectSlice.reducer
