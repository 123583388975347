/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = { showEmail: false }

const emailModalSlice = createSlice({
  name: 'visible',
  initialState: initialState,

  reducers: {
    showEmailModal: (state) => {
      //show
      state.showEmail = !state.showEmail
    },
  },
})

export const { showEmailModal } = emailModalSlice.actions

export default emailModalSlice.reducer
//store r
