/* import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
 */
/* eslint-disable */
import { configureStore } from '@reduxjs/toolkit'
import departmentModalReducer from 'src/redux/Reducer/departmentReducer/ModalSlice'
import departmentDeleteModalReducer from 'src/redux/Reducer/departmentReducer/DeleteSlice'
import emailModalReducer from 'src/redux/Reducer/emailReducer/emailSlice'
import sendEmailModalReducer from 'src/redux/Reducer/sendEmailReducer/sendEmailSlice'
import errorSaveReducer from 'src/redux/Reducer/errorSaveReducer/errorSaveSlice'
import taxReducer from 'src/redux/Reducer/TaxReducer/TaxSlice'
// import errorModalReducer from 'src/redux/Reducer/errorReducer/errorSlice'

import { upsertServiceReducer } from './redux/Reducer/servicesReducers'
import { deleteServiceReducer } from './redux/Reducer/servicesReducers'
import showMedReducer from './redux/Reducer/ShowModal2/showMedSlice'
import deleteMedReducer from './redux/Reducer/ShowModal2/deleteMedSlice'
import { masterLayoutReducer } from './redux/Reducer'
import { navReducer } from './redux/Reducer/navReducer'
import { modal } from './redux/Reducer'
import { theMainData } from './redux/Reducer/committeesAndCharities'
import { theMainDataDonators } from './redux/Reducer/donators'
import { minorListReducer } from './redux/Reducer/minorReducer'
import { searchReducer } from './redux/Reducer'
import employeeReducer from './redux/Reducer/Employees/EmployeesSlice'
import employeeReducer2 from './redux/Reducer/Employees copy/EmployeesSlice'
import reservationReducer from './redux/Reducer/Reservation/ReservationSlice'
import replacedOrphanSponsorshipsReducera from './redux/Reducer/ReplacedOrphanSponsorships/ReplacedOrphanSponsorshipsSlice'
import needyFamiliesReducera from './redux/Reducer/needyfamilies/needyFamiliesSlice'
import dataFamiliesReducera from './redux/Reducer/datafamilies/dataFamiliesSlice'
import StostOporphansSponsorshipReducer from './redux/Reducer/StostOporphansSponsorship/StostOporphansSponsorshipSlice'
import zipReducer from './redux/Reducer/Zip/zipSlice'
import FollowUpDocumentsReducer from './redux/Reducer/FollowUpDocuments/FollowUpDocumentsSlice'
import AccountsTreeReducer from './redux/Reducer/AccountsTree/AccountsTreeSlice'
import telephoneBookReducer from './redux/Reducer/TelephoneBook/telephoneBookSlice'
import OrphansExpensesReducer from './redux/Reducer/OrphansExpenses/OrphansExpensesSlice'
import DisbursementsNeedyFamiliesReducer from './redux/Reducer/DisbursementsNeedyFamilies/DisbursementsNeedyFamiliesSlice'
import BondsReducer from './redux/Reducer/bonds/BondsSlice'
import ProjectDisbursementsReducer from './redux/Reducer/ProjectDisbursements/ProjectDisbursementsSlice'
import BondsReducer2 from './redux/Reducer/bonds copy/BondsSlice'
import BondsReducer3 from './redux/Reducer/bonds3/Bonds3Slice'
import BondsFlagReducer from './redux/Reducer/bondsFlag/bondsFlagSlice'
import BondsDiffFlagReducer from './redux/Reducer/bondsFlagDiff/bondsFlagDiffSlice'
import BondsFlagfamilyPeriodicReducer from './redux/Reducer/bondsFlagfamily/bondsFlagfamilySlice'
import BondsFlagAmountReducer from './redux/Reducer/bondsFlagAmount/bondsFlagAmountSlice'
import BondsFlagOtherProjectsReducer from './redux/Reducer/bondsFlagOtherProjects/bondsFlagOtherProjectsSlice'
import BondsFlagOtherProjectsFamilyReducer from './redux/Reducer/bondsFlagOtherProjectsFamily/bondsFlagOtherProjectsFamilySlice' //
import CorrespondenceReducer from './redux/Reducer/correspondence/correspondenceSlice'
import OrphanSponsorShipsReducer from './redux/Reducer/OrphanSponsorShips/OrphanSponsorShipsSlice'
import SponsoredOrphanReducer from './redux/Reducer/SponsoredOrphans/OrphansMainDataSlice'
import SponsoredOrphanFamilyDataReducer from './redux/Reducer/SponsoredOrphans/OrphanFamilyDataSlice'
import editTableReducer from './redux/Reducer/EditableTable/EditOnClickSlice'
import rowSelectionReducer from './redux/Reducer/EditableTable/RowSelectionSlice'
import put_postReducer from './redux/Reducer/EditableTable/PutPostSlice'
import put_postReducer2 from './redux/Reducer/EditableTable/PutPostSlice2'
import notApprovedOrphanReducer from './redux/Reducer/UnSponsoredOrphans/NotApprovedMainData'
import { theMainDataOrphansFamilies } from './redux/Reducer/orphansfamilies'
import { theMainDataOrphansFamiliesAccounts } from './redux/Reducer/orphansfamiliesAccounts'
import NotAppFamilyDataReducer from './redux/Reducer/UnSponsoredOrphans/NotApprovedFamilyData'
import SponsorshipDataReducer from './redux/Reducer/SponsoredOrphans/SponsorshipData'
import { brokerCard } from './redux/Reducer/brokerCard'
import { callingCode } from './redux/Reducer/callingCodes'
import { orphanFamilyMember } from './redux/Reducer/orphanFamilyMember'
import showSearchFlagReducer from './redux/Reducer/Search/showSearchSlice'
import countriesInfoModalReducer from 'src/redux/Reducer/countriesReducer/countriesSlice'
import sponsorCallReducer from './redux/Reducer/SponsoredOrphans/SponsorCommunication'
import stopOrphanSponsership from './redux/Reducer/stopOrphanSponsership/stopOrphanSponsership'
import Row2Reducer from './redux/Reducer/EditableTable/Row2Slice'
import errorModalReducer from 'src/redux/Reducer/errorReducer/errorSlice'
import periodicFamilyResearchReducer from './redux/Reducer/periodicOrphanResearches/PeriodicOrphanFamilyResearch'
import periodicOrphanResearchReducer from './redux/Reducer/periodicOrphanResearches/PeriodicOrphanResearch'
import needyFamilySponsorshipsReducer from './redux/Reducer/needyfamilies/needyFamilySponsorships'
import { theMainDataPoorFamiliesAccounts } from './redux/Reducer/poorfamilies'
import { arabicDictionaryReducer } from './redux/Reducer/dictionaryArabicNamesReducer'
import { CharityProjectsReducer } from './redux/Reducer/CharityProjectsReducer'
import MultiSelectReducer from './redux/Reducer/EditableTable/MultiSelectSlice'
import MultiSelectReducer2 from './redux/Reducer/EditableTable/MultiSelectSlice2'
import previewRowReducer from './redux/Reducer/EditableTable/PreivewRow'
import NominationOfFamiliesInNeedOfAssistanceReducer from './redux/Reducer/NominationOfFamiliesInNeedOfAssistance/NominationOfFamiliesInNeedOfAssistanceSlice'
import { bookVoucher } from './redux/Reducer/bookVoucher'
import ZakaeReducer from './redux/Reducer/ZakaReducer/ZakaSlice'
import EnteringChecksReducer from './redux/Reducer/EnteringChecksReducer/EnteringChecksSlice'
import multiRowSelectionSlice from './redux/Reducer/EditableTable/mutliRowSelection'
import needyFamilyExpensesReducer from './redux/Reducer/needyfamilies/NeedyFamilyExpenses'
import BondsSlice from './redux/Reducer/bonds/BondsSlice'
import UnsavedChangesSlice from './redux/Reducer/UnsavedChanges/UnsavedChanges'
import SelectedRowIndexes from './redux/Reducer/EditableTable/SelectedRowIndexes'

const store = configureStore({
  reducer: {
    errorSave: errorSaveReducer,
    tax: taxReducer,

    emailModal: emailModalReducer,
    sendEmailModal: sendEmailModalReducer,

    departmentDeleteModal: departmentDeleteModalReducer,
    countriesInfoModal: countriesInfoModalReducer,
    departmentModal: departmentModalReducer,
    addServicesModal: upsertServiceReducer,
    deleteServicesModal: deleteServiceReducer,
    errorModalReducer: errorModalReducer,
    showModal2: showMedReducer,
    theMainDataOrphansFamilies: theMainDataOrphansFamilies,
    theMainDataOrphansFamiliesAccounts: theMainDataOrphansFamiliesAccounts,
    theMainDataPoorFamiliesAccounts: theMainDataPoorFamiliesAccounts,
    deleteMedModal: deleteMedReducer,
    masterLayoutReducer: masterLayoutReducer,
    navReducer: navReducer,
    modal: modal,
    NominationOfFamiliesInNeedOfAssistanceReducer: NominationOfFamiliesInNeedOfAssistanceReducer,
    ZakaeReducer: ZakaeReducer,
    EnteringChecksReducer: EnteringChecksReducer,
    table: put_postReducer,
    table2: put_postReducer2,

    theMainData: theMainData,
    theMainDataDonators: theMainDataDonators,
    minorListReducer: minorListReducer,
    searchReducer: searchReducer,
    OrphanSponsorShipsReducer: OrphanSponsorShipsReducer,
    employeeData: employeeReducer,
    employeeData2: employeeReducer2,
    reservationData: reservationReducer,
    replacedOrphanSponsorshipsData: replacedOrphanSponsorshipsReducera,
    needyFamiliesData: needyFamiliesReducera,
    dataFamilies: dataFamiliesReducera,
    StostOporphansSponsorshipData: StostOporphansSponsorshipReducer,
    zipData: zipReducer,
    followUpDocumentsReducerData: FollowUpDocumentsReducer,
    AccountsTreeData: AccountsTreeReducer,
    telephoneBookData: telephoneBookReducer,
    orphansExpenses: OrphansExpensesReducer,
    disbursementsNeedyFamilies: DisbursementsNeedyFamiliesReducer,
    Bonds: BondsReducer,
    Bonds2: BondsReducer2,
    Bonds3: BondsReducer3,
    BondsFlag: BondsFlagReducer,
    BondsDiffFlag: BondsDiffFlagReducer,
    familyPeriodicFlag: BondsFlagfamilyPeriodicReducer,
    BondsFlagAmount: BondsFlagAmountReducer,
    BondsFlagOtherProjects: BondsFlagOtherProjectsReducer,
    BondsFlagOtherProjectsFamily: BondsFlagOtherProjectsFamilyReducer,
    ProjectDisbursements: ProjectDisbursementsReducer,
    Correspondence: CorrespondenceReducer,
    editTable: editTableReducer,
    row: rowSelectionReducer,
    row2: Row2Reducer,
    orphanData: SponsoredOrphanReducer,
    orphanFamilyData: SponsoredOrphanFamilyDataReducer,
    notApprovedOrphanMainData: notApprovedOrphanReducer,
    notAppFamilyData: NotAppFamilyDataReducer,
    sponsorshipData: SponsorshipDataReducer,
    brokerCard: brokerCard,
    callingCode: callingCode,
    orphanFamilyMember: orphanFamilyMember,
    showSearchFlag: showSearchFlagReducer,
    sponsorCall: sponsorCallReducer,
    stopOrphanSponsership: stopOrphanSponsership,
    PeriodicOrphanFamilyResearch: periodicFamilyResearchReducer,
    periodicOrhpanResearch: periodicOrphanResearchReducer,
    needyFamilySponsorships: needyFamilySponsorshipsReducer,
    arabicDictionary: arabicDictionaryReducer,
    CharityProjects: CharityProjectsReducer,
    MultiSelectRows: MultiSelectReducer,
    MultiSelectRows2: MultiSelectReducer2,
    nFamilyExpenses: needyFamilyExpensesReducer,
    previewRow: previewRowReducer,
    bookVoucher: bookVoucher,
    multiRowSelection: multiRowSelectionSlice,
    UnsavedChanges: UnsavedChangesSlice,
    rowsIndexes: SelectedRowIndexes,
  },
})
export default store
