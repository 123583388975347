/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {

  action: ACTION_ENUM.PREVIEW,
}
export const Zaka = createSlice({
  name: 'visibleZaka',
  initialState: { visibleZaka: initialValue },
  reducers: {
    setZaka: (state, action) => {
      state.visibleZaka = action.payload
    },
    cleanZaka: (state) => {
      state.visibleZaka = initialValue
    },
  },
})
export const { setZaka, cleanZaka } = Zaka.actions
export default Zaka.reducer
