/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  needyfamilyid: null,
  familyname: '',
  approved: null,
  donatorid: null,
  fullname: '',
  paymentstate: null,
  paymentstatetext: '',
  paymentstopreason: '',
  sponsorshipstate: null,
  sponsorshipstatetext: '',
  sponsorshipamount: '',
  managerialdeduction: '',
  maincenterdeduction: '',
  educationdeduction: '',
  mediactiondeduction: '',
  otherdeduction: '',
  sponsorshipnetamount: '',
  sponsorshipdate: '',
  sponsorshiplongdate: null,
  sponsorshipstartdate: '',
  sponsorshiplongstartdate: null,
  reportingperiod: null,
  paymentmethod: null,
  paymentmethodtext: '',
  paymenttype: null,
  paymenttypetext: '',
  note: '',
  inactive: null,
  replacementaction: null,
  sponsorshipstopdate: '',
  sponsorshiplongstopdate: null,
  remindingperiod: null,
  branch: null,
  branchtext: '',
  allowbalance: null,
  allowbalancetext: '',
  followupnote: null,
  followupnotetext: null,
  action: ACTION_ENUM.PREVIEW,
}
export const needyFamilySponsorships = createSlice({
  name: 'visibleNFSponsorshipData',
  initialState: { visibleNFSponsorshipData: initialValue },
  reducers: {
    setNFSponsorshipData: (state, action) => {
      state.visibleNFSponsorshipData = action.payload
    },
    cleanUpNFSponsorshipData: (state) => {
      state.visibleNFSponsorshipData = initialValue
    },
  },
})

export const { setNFSponsorshipData, cleanUpNFSponsorshipData } = needyFamilySponsorships.actions
export default needyFamilySponsorships.reducer
