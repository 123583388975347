/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,

  action: ACTION_ENUM.PREVIEW,
}
// dataFamiliesSlice
export const dataFamiliesSlice = createSlice({
  name: 'visibleDataFamilies',
  initialState: { visibleDataFamilies: initialValue },
  reducers: {
    setDataFamilies: (state, action) => {
      state.visibleDataFamilies = action.payload
    },
    cleanUpDataFamilies: (state) => {
      state.visibleDataFamilies = initialValue
    },
  },
})

export const {
  setDataFamilies,
  cleanUpDataFamilies,
} = dataFamiliesSlice.actions
export default dataFamiliesSlice.reducer
