/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recId: null,
  action: ACTION_ENUM.PREVIEW,
}

export const DisbursementsNeedyFamiliesSlice = createSlice({
  name: 'visibleDisbursementsNeedyFamiliesData',
  initialState: { visibleDisbursementsNeedyFamiliesData: initialValue },
  reducers: {
    setDisbursementsNeedyFamiliesData: (state, action) => {
      state.visibleDisbursementsNeedyFamiliesData = action.payload
    },
    cleanDisbursementsNeedyFamiliesData: (state) => {
      state.visibleDisbursementsNeedyFamiliesData = initialValue
    },
  },
})

export const { setDisbursementsNeedyFamiliesData, cleanDisbursementsNeedyFamiliesData } =
  DisbursementsNeedyFamiliesSlice.actions
export default DisbursementsNeedyFamiliesSlice.reducer
