/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  step: 0,
  tempStep: null,
  fullRow: null,
  rowToUse: null,
  page: '',
  flagP: false,
}
export const masterLayoutSlice = createSlice({
  name: 'steps',
  initialState: { steps: initialValue },
  reducers: {
    setFullRow: (state, action) => {
      state.steps.fullRow = action.payload
    },
    setRowtoUse: (state, action) => {
      state.steps.rowToUse = action.payload
    },
    setTmepSteps: (state, action) => {
      state.steps.tempStep = action.payload
    },
    setSteps: (state, action) => {
      state.steps.step = action.payload
    },
    setPages: (state, action) => {
      state.steps.page = action.payload
    },
    setNextSteps: (state) => {
      state.steps.step += 1
    },
    setManyNextSteps: (state) => {
      state.steps.step += 10
    },
    setPreSteps: (state) => {
      state.steps.step -= 1
    },
    setFlagP: (state, action) => {
      state.steps.flagP = action.payload
    },
    setManyPreSteps: (state) => {
      state.steps.step -= 10
    },
    cleanUpMasterLayout: (state) => {
      state.steps = initialValue
    },
  },
})

export const {
  setFullRow,
  setTmepSteps,
  setSteps,
  setManyNextSteps,
  setManyPreSteps,
  setNextSteps,
  setPreSteps,
  setPages,
  setFlagP,
  cleanUpMasterLayout,
  setRowtoUse,
} = masterLayoutSlice.actions
export default masterLayoutSlice.reducer
