/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  erorrDataorphanPeriodic: true,
}
//bondsFlagSlice
export const bondsFlagSlice = createSlice({
  name: 'visibleBondsSliceFlag',
  initialState: { visibleBondsSliceFlag: initialValue },
  reducers: {
    setBondsFlag: (state, action) => {
      state.visibleBondsSliceFlag = action.payload
    },
  },
})

export const { setBondsFlag } = bondsFlagSlice.actions
export default bondsFlagSlice.reducer
