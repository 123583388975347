/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recId: null,
  committeeid: null,
  committeename: null,
  committeeemployee: null,
  orphanid: null,
  orphanfullname: null,
  birthdate: null,
  sponsorshipstopreason: null,
  applicationdate: [new Date().getFullYear(), new Date().getMonth() + 1, , new Date().getDay()],
  applicationlongdate: null,
  note: null,
  inactive: null,
  userid: null,
  rowstamp: null,
  creationuserid: null,
  creationrowstamp: null,
  newflag: null,
  modifiedflag: null,
  deletedflag: null,
  replacementaction: null,
  orphanresearchdate: null,
  rowversion: null,
  action: ACTION_ENUM.PREVIEW,
}
// sponsorshipstartdate: [new Date().getFullYear(), new Date().getMonth() + 1, "1"].join('/'),

export const StostOporphansSponsorshipSlice = createSlice({
  name: 'visibleStostOporphansSponsorshipData',
  initialState: { visibleStostOporphansSponsorshipData: initialValue },
  reducers: {
    setStostOporphansSponsorshipData: (state, action) => {
      state.visibleStostOporphansSponsorshipData = action.payload
    },
    cleanUpsetStostOporphansSponsorshipData: (state) => {
      state.visibleStostOporphansSponsorshipData = initialValue
    },
  },
})

export const { setStostOporphansSponsorshipData, cleanUpsetStostOporphansSponsorshipData } =
  StostOporphansSponsorshipSlice.actions
export default StostOporphansSponsorshipSlice.reducer
