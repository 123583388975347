/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  row: null,
}

export const PreivewRow = createSlice({
  name: 'row',
  initialState: { row: initialState },
  reducers: {
    setPreviewRow: (state, action) => {
      state.row = action.payload
    },
  },
})

export const { setPreviewRow } = PreivewRow.actions

export default PreivewRow.reducer
