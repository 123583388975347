/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  rows: null,
}

export const MultiSelectSlice2 = createSlice({
  name: 'rows2',
  initialState: { rows: initialState },
  reducers: {
    setRows2: (state, action) => {
      state.rows = action.payload
    },

    cleanUpRows2: (state) => {
      state.rows = initialState
    },
  },
})

export const { setRows2, cleanUpRows2 } = MultiSelectSlice2.actions

export default MultiSelectSlice2.reducer
