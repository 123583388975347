/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  table_id: null,
  isSaved: false,
  putArr: [],
  postArr: [],
  onEnterColumns: false,
  alldata: [],
}

export const PutPostSlice2 = createSlice({
  name: 'table2',
  initialState: { data: initialState },
  reducers: {
    setdata2: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setdata2 } = PutPostSlice2.actions

export default PutPostSlice2.reducer
