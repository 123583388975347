/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  id: null,
  arabicname: null,
  englishname: null,
  foreignname: null,
  action: ACTION_ENUM.PREVIEW,
}
export const ArabicDictionary = createSlice({
  name: 'visibleArabicDictionary',
  initialState: { visibleArabicDictionary: initialValue },
  reducers: {
    setArabicDictionary: (state, action) => {
      state.visibleArabicDictionary = action.payload
    },
    cleanUpArabicDictionary: (state) => {
      state.visibleArabicDictionary = initialValue
    },
  },
})

export const { setArabicDictionary, cleanUpArabicDictionary } = ArabicDictionary.actions
export default ArabicDictionary.reducer
