/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,

  action: ACTION_ENUM.PREVIEW,
}
export const AccountsTreeSlice = createSlice({
  name: 'visibleAccountsTreeData',
  initialState: { visibleAccountsTreeData: initialValue },
  reducers: {
    setAccountsTreeData: (state, action) => {
      state.visibleAccountsTreeData = action.payload
    },
    cleanAccountsTreeData: (state) => {
      state.visibleAccountsTreeData = initialValue
    },
  },
})
//AccountsTreeSlice
export const { setAccountsTreeData, cleanAccountsTreeData } = AccountsTreeSlice.actions
export default AccountsTreeSlice.reducer
