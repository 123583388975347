/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'
const initialValue = {
  recId: null,
  action: ACTION_ENUM.PREVIEW,
}
export const NeedyFamilyExpenses = createSlice({
  name: 'visibleneedyFamilyExpenses',
  initialState: { visibleneedyFamilyExpenses: initialValue },
  reducers: {
    setnFamilyExpensesData: (state, action) => {
      state.visibleneedyFamilyExpenses = action.payload
    },
    cleannFamilyExpensesData: (state) => {
      state.visibleneedyFamilyExpenses = initialValue
    },
  },
})

export const { setnFamilyExpensesData, cleannFamilyExpensesData } = NeedyFamilyExpenses.actions
export default NeedyFamilyExpenses.reducer
