/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recId: null,
  zipcodeid: null,
  description: null,

  cityid: { value: '', label: '' },
  // citytext: null,

  countryid: { value: '', label: '' },
  // countrytext: null,

  inactive: null,
  action: ACTION_ENUM.PREVIEW,
}
export const ZipSlice = createSlice({
  name: 'visibleZipData',
  initialState: { visibleZipData: initialValue },
  reducers: {
    setZipData: (state, action) => {
      state.visibleZipData = action.payload
    },
    cleanZipData: (state) => {
      state.visibleZipData = initialValue
    },
  },
})

export const { setZipData, cleanZipData } = ZipSlice.actions
export default ZipSlice.reducer
